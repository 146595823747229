<template>
	<div class="">
		<b-button v-b-modal.new-category-modal variant="primary">
			New Category
		</b-button>
		<b-modal
			id="new-category-modal"
			ref="newCategoryModal"
			title="Add new category"
			ok-title="Save"
			centered
			cancel-variant="outline-secondary"
			lazy
			@hidden="resetModal"
			@ok="handleOk"
		>
			<validation-observer ref="simpleRules">
				<b-form>
					<b-row>
						<!-- name -->
						<b-col cols="12">
							<b-form-group label="Category name" label-for="name">
								<validation-provider #default="{ errors }" name="Category name" rules="required">
									<b-form-input
										id="name"
										v-model="formData.name"
										autofocus
										:state="errors.length > 0 ? false : null"
										placeholder="Name"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<b-col cols="12">
							<div class="px-1 py-50 mt-50 border rounded" style="background-color: #f9f9f9">
								<b-form-group label="Subcategory name" label-for="sub-name">
									<!-- <validation-provider #default="{ errors }" name="Subcategory name" rules="required"> -->
									<div class="d-flex">
										<b-form-input id="sub-name" ref="subCatName" v-model="subCategory" placeholder="Name" class="flex-fill" />
										<b-button variant="primary" class="btn-icon ml-25" @click="addSubCat">
											<feather-icon icon="PlusIcon" />
										</b-button>
									</div>
									<!-- <small class="text-danger">{{ errors[0] }}</small> -->
									<!-- </validation-provider> -->
								</b-form-group>

								<span class="mt-1 mb-50 d-block" style="font-size: 12px">Subcategories</span>

								<div class="pl-50">
									<div v-for="subCat in formData.children" :key="subCat.id">
										<div v-if="!subCat.edit" class="py-50 px-1 mb-50 sub-cat">
											<span>{{ subCat.name }}</span>
											<b-button
												v-if="isEdit"
												variant="flat-primary"
												size="sm"
												class="btn-icon ml-25"
												@click="subCat.edit = true"
											>
												<feather-icon icon="Edit3Icon" />
											</b-button>
											<b-button v-else variant="flat-danger" size="sm" class="btn-icon ml-25" @click="deleteSubCat(subCat.id)">
												<feather-icon icon="Trash2Icon" />
											</b-button>
										</div>
										<div v-else class="mb-50 d-flex">
											<b-form-input v-model="subCat.name" class="py-50 rounded-0" />
											<b-button variant="primary" class="btn-icon ml-25" @click="subCat.edit = false">
												<feather-icon icon="CheckIcon" />
											</b-button>
										</div>
									</div>
								</div>
							</div>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BFormInput, BFormGroup, BForm, BRow, BCol, BButton } from 'bootstrap-vue';
import { required } from '@validations';
import { generateId } from '@/utils/helpers';

export default {
	name: 'NewCategory',

	components: {
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BFormGroup,
		BForm,
		BRow,
		BCol,
		BButton,
	},

	props: {
		data: {
			type: Object,
			default: () => ({
				name: '',
				children: [],
			}),
		},
	},

	data: () => ({
		required,
		subCategory: '',
		formData: undefined,
	}),

	computed: {
		isEdit() {
			return this.data.name !== '';
		},
	},

	watch: {
		data: {
			handler(val) {
				this.formData = {
					name: val.name,
					children: val.children.map((child) => ({ ...child, edit: false })),
				};
			},
			immediate: true,
		},
	},

	methods: {
		resetModal() {
			this.$emit('hidden');
		},

		addSubCat() {
			if (this.subCategory.trim().length <= 0) return;

			this.formData.children.push({
				id: generateId(),
				name: this.subCategory,
				edit: false,
			});

			this.subCategory = '';
			this.$refs.subCatName.focus();
		},

		deleteSubCat(id) {
			this.formData.children = this.formData.children.filter((subCat) => subCat.id !== id);
		},

		handleOk(bvModalEvt) {
			// Prevent modal from closing
			bvModalEvt.preventDefault();
			// Trigger submit handler
			this.handleSubmit();
		},

		async handleSubmit() {
			const success = await this.$refs.simpleRules.validate();
			if (!success) return;

			this.$emit('submitted', {
				name: this.formData.name,
				children: this.formData.children.map((subCat) => ({
					id: subCat.id,
					name: subCat.name,
				})),
			});

			// Hide the modal manually
			this.$nextTick(() => {
				this.$refs.newCategoryModal.toggle();
			});

			this.$emit('done');
			this.formData = {
				name: '',
				children: [],
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.sub-cat {
	background-color: #fff;
	border-left: 4px solid #8bc53f;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
</style>
