<template>
	<div>
		<base-table :headers="headers" :items="categories" paginate table-top>
			<template #action>
				<new-category :data="categoryToEdit" @submitted="addCategory" @hidden="handleHidde" />
			</template>

			<template #cell(children)="{ item }">
				{{ item.children.length }}
			</template>

			<template #cell(actions)="{ item }">
				<b-button
					v-b-tooltip.hover.top="'Edit'"
					v-b-modal.new-category-modal
					variant="outline-primary"
					class="btn-icon cursor-pointer mx-1"
					size="sm"
					@click="editCategory(item)"
				>
					<feather-icon icon="Edit3Icon" size="16" />
				</b-button>
			</template>
		</base-table>
	</div>
</template>

<script>
import store from '@/store';
import { BButton, VBTooltip } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import NewCategory from './new-category.vue';

export default {
	name: 'Categories',

	components: { BButton, BaseTable, NewCategory },

	directives: {
		'b-tooltip': VBTooltip,
	},

	data: () => ({
		headers: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'name', label: 'Name', sortable: true },
			{ key: 'children', label: 'Subcategories', sortable: true },
			{ key: 'actions', label: 'Actions' },
		],

		categoryToEdit: undefined,
		isEdit: false,
		categories: [],
	}),

	created() {
		this.fetchCategories();
	},

	methods: {
		handleHidde() {
			this.isEdit = false;
			this.categoryToEdit = undefined;
		},

		async fetchCategories() {
			try {
				this.categories = await store.dispatch('categories/getCategories');
			} catch (error) {
				console.log(error);
			}
		},

		async addCategory(data) {
			if (this.isEdit) {
				await store.dispatch('categories/editCategory', { id: this.categoryToEdit.id, catData: data });
			} else {
				await store.dispatch('categories/addCategory', data);
			}
		},

		editCategory(item) {
			this.categoryToEdit = item;
			this.isEdit = true;
		},
	},
};
</script>

<style lang="scss" scoped></style>
