<template>
	<div>
		<b-tabs pills>
			<b-tab title="Categories">
				<categories />
			</b-tab>
			<b-tab title="Solutions">
				<solutions />
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';
import Categories from './categories/Index.vue';
import Solutions from './solutions/Index.vue';

export default {
	name: 'GRMSettings',

	components: { BTabs, BTab, Categories, Solutions },
};
</script>

<style lang="scss" scoped></style>
