<template>
	<div>
		<base-table :headers="headers" :items="solutions" paginate table-top>
			<template #action>
				<new-solution :data="solutionToEdit" @submitted="addSolution" @hidden="handleHidde" />
			</template>

			<template #cell(actions)="{ item }">
				<b-button
					v-b-tooltip.hover.top="'Edit'"
					v-b-modal.new-solution-modal
					variant="outline-primary"
					class="btn-icon cursor-pointer mx-1"
					size="sm"
					@click="editSolution(item)"
				>
					<feather-icon icon="Edit3Icon" size="16" />
				</b-button>
			</template>
		</base-table>
	</div>
</template>

<script>
import store from '@/store';
import { BButton, VBTooltip } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import NewSolution from './new-solution.vue';

export default {
	name: 'Categories',

	components: { BButton, BaseTable, NewSolution },

	directives: {
		'b-tooltip': VBTooltip,
	},

	data: () => ({
		headers: [{ key: 'id', label: 'ID', sortable: true }, { key: 'name', label: 'Name', sortable: true }, { key: 'actions' }],

		solutionToEdit: undefined,
		isEdit: false,

		solutions: [],
	}),

	created() {
		this.fetchSolutions();
	},

	methods: {
		handleHidde() {
			this.isEdit = false;
			this.solutionToEdit = undefined;
		},

		async fetchSolutions() {
			try {
				this.solutions = await store.dispatch('solutions/getSolutions');
			} catch (error) {
				console.log(error);
			}
		},

		async addSolution(data) {
			if (this.isEdit) {
				await store.dispatch('solutions/editSolution', { id: this.solutionToEdit.id, solData: data });
			} else {
				await store.dispatch('solutions/addSolution', data);
			}
		},

		editSolution(item) {
			this.solutionToEdit = item;
			this.isEdit = true;
		},
	},
};
</script>

<style lang="scss" scoped></style>
