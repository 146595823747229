<template>
	<div class="">
		<b-button v-b-modal.new-solution-modal variant="primary">
			New Solution
		</b-button>
		<b-modal
			id="new-solution-modal"
			ref="newSolutionModal"
			title="Add new solution"
			ok-title="Save"
			centered
			cancel-variant="outline-secondary"
			lazy
			@hidden="resetModal"
			@ok="handleOk"
		>
			<validation-observer ref="simpleRules">
				<b-form>
					<b-row>
						<!-- name -->
						<b-col cols="12">
							<b-form-group label="Solution name" label-for="name">
								<validation-provider #default="{ errors }" name="Solution name" rules="required">
									<b-form-input
										id="name"
										v-model="formData.name"
										autofocus
										:state="errors.length > 0 ? false : null"
										placeholder="Name"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BFormInput, BFormGroup, BForm, BRow, BCol, BButton } from 'bootstrap-vue';
import { required } from '@validations';

export default {
	name: 'NewSolution',

	components: {
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BFormGroup,
		BForm,
		BRow,
		BCol,
		BButton,
	},

	props: {
		data: {
			type: Object,
			default: () => ({
				name: '',
			}),
		},
	},

	data: () => ({
		required,
		formData: undefined,
	}),

	computed: {
		isEdit() {
			return this.data.name !== '';
		},
	},

	watch: {
		data: {
			handler(val) {
				this.formData = { ...val };
			},
			immediate: true,
		},
	},

	methods: {
		resetModal() {
			this.$emit('hidden');
		},

		handleOk(bvModalEvt) {
			// Prevent modal from closing
			bvModalEvt.preventDefault();
			// Trigger submit handler
			this.handleSubmit();
		},

		async handleSubmit() {
			const success = await this.$refs.simpleRules.validate();
			if (!success) return;

			this.$emit('submitted', { ...this.formData });

			// Hide the modal manually
			this.$nextTick(() => {
				this.$refs.newSolutionModal.toggle();
			});

			this.$emit('done');
		},
	},
};
</script>

<style lang="scss" scoped></style>
